/* Common Style Start */
::-webkit-scrollbar {
  width: 1px;
}
::-webkit-scrollbar-track {
  background: #242526;
}
::-webkit-scrollbar-thumb {
  background: #3a3b3c;
}
img {
  width: 100%;
}
.cw {
  color: #fff;
}
.f-m-b {
  margin-bottom: 33%;
}
.padding-top {
  padding-top: 130px;
}
.bg-color-w {
  background: #fff !important;
  z-index: 1;
  position: relative;
}
.position-relative .col-lg-2 {
  padding: 0;
}
.position-relative .col-lg-10 {
  border: 1px solid #ccc6;
  padding: 0;
}
.position-relative.active {
  border: 2px solid #2311b9;
  padding: 6%;
}
#carousel-selector-0 img.active,
#carousel-selector-1 img.active,
#carousel-selector-2 img.active,
#carousel-selector-3 img.active {
  border: 2px solid #2311b9;
  padding: 6%;
}
#carousel-selector-0,
#carousel-selector-1,
#carousel-selector-2,
#carousel-selector-3 {
  border: 3px solid #ccc6;
}
.bg-color-b {
  background: #000000;
}
.border-line {
  width: 60px;
  height: 4px;
  display: inline-block;
  position: relative;
  bottom: 4px;
  padding: 0 11px 0 9px;
  margin: 0 10px 0 0;
}
.tac {
  text-align: center;
}
.header-para {
  width: 80%;
  margin: 0 auto;
}
.aic {
  align-items: center;
}
.para {
  font-size: 22px;
  line-height: 26px;
  margin: 0 auto;
  width: 70%;
}
.pt {
  padding-top: 2%;
}
.pb {
  padding-bottom: 2%;
}
.mt {
  margin-top: 2%;
}
.mb {
  margin-bottom: 2%;
}
.home-header {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 60px;
  /* text-align: center; */
  color: #ffffff;
}
.blue-header {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 35px;
  /* text-decoration-line: underline; */
  color: #121085;
}
/* Common Style End */

/* Navbar Start */
.Navbar .Navigation {
  background: #fff;
  box-shadow: 0 1px 8px 0 #ccc;
}

.mail-with-nav .activesed {
  height: 4rem;
  width: 100%;
  position: fixed;
  top: 0px;
  transition: 0.3s linear;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 40;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.08);
  /* padding: 0 7%; */
}
.mail-with-nav .hidden {
  height: 4rem;
  width: 100%;
  z-index: 40;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.08);
  position: fixed;
  top: -45px;
  transition: 0.3s linear;
}
.Navbar {
  position: fixed;
  width: 100%;
  z-index: 10000;
  background: #fff;
}

.dropdown-toggle {
  background: none;
  color: #000;
  outline: none;
  border: none;
}

.ph-mail {
  background: #121085;
}
.navbar-nav .dropdown-menu {
  width: 280%;
  line-height: 40px;
}
.ph-with-mail {
  display: flex;
  justify-content: end;
  padding: 10px 0;
}
.ph-with-mail p {
  margin: 0 0;
}
.ph-with-mail p a {
  color: #fff;
  text-decoration: none;
}

/* Navigation Start */

.Navigation img {
  width: 100%;
}
.Navigation nav {
  padding: 0.5% 0;
}
.Navigation .navbar-collapse {
  justify-content: end;
}
.Navigation .navbar-collapse ul li {
  padding: 0px 20px;
  display: flex;
  align-items: center;
}
.Navigation .navbar-collapse ul li a {
  font-weight: 600;
  font-size: 16px;
  color: #000 !important;
  text-decoration: none;
}
#contactForm {
  width: 86%;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}
/* Enquiry From Start */
.Navigation .modal.show .modal-dialog {
  transform: none;
  max-width: 56%;
  display: flex;
  align-items: center;
  height: 100%;
}
/* Navbar End */

/* Home Pages Start */
/* Home Banner Section Start */
.home-banner {
  padding-top: 113px;
}
.home-banner .carousel-indicators {
  position: absolute;
  bottom: 50%;
  left: 83%;
  z-index: 2;
  margin-right: 0%;
  margin-bottom: 0rem;
  margin-left: 0%;
  transform: rotate(90deg);
}
.home-banner .carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #fff;
}
.home-banner .item .carousel-caption {
  color: #ffffff;
  text-align: left;
}
.home-banner .item .carousel-caption p {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.home-banner .item .carousel-caption h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  margin: 28px 0;
}
.home-banner .item .carousel-caption button {
  width: 248px;
  height: 50px;
  color: #121085;
  background: #ffffff;
  border-radius: 3px;
}
.home-banner .carousel-caption {
  position: absolute;
  bottom: 8.25rem;
}
.home-bg-card-1 {
  background-image: url("./Images/Home/Home28.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 85vh;
  border: none;
}
.home-bg-card-2 {
  background-image: url("./Images/Home/Home1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
}
.home-bg-card-3 {
  background-image: url("./Images/Home/Home29.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
}
.home-banner .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
  transform: rotate(90deg);
  position: absolute;
  right: 0;
  top: 50%;
}
.home-banner .owl-theme .owl-dots .owl-dot span {
  background: #869791;
}
.home-banner .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #d6d6d6;
}
.home-banner .owl-theme .owl-dots .owl-dot span {
  width: 30px;
  height: 3px;
}
.home-banner .owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
  height: 85vh;
}
.modal-backdrop.show {
  opacity: 83%;
  z-index: 1;
}
/* Products Section Start */
.Products .cards {
  background: #f0f0f0;
  margin-bottom: 40px;
}
.Products .card-img {
  padding: 2% 0;
  height: 30vh;
}

.Products .cards img {
  width: 100%;
  margin: 0px auto;
  display: flex;
  height: 100%;
  padding: 15px;
}
.card-img-5 img,
/* .card-img-4 img, */
.card-img-1 img {
  position: relative;
  bottom: 30px;
  width: 100% !important;
  margin: 0px auto !important;
  display: flex;
  height: 130% !important;
}
.card-img-2 img {
  position: relative;
  bottom: -24px;
  width: 100% !important;
  margin: 0px auto !important;
  display: flex;
  height: auto !important;
}
.Products .card-content {
  background: #121085;
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 14px 0;
}
.Products .card-content {
  background: #121085;
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 14px 0;
}
.Products .card-content p {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}

.Products .card-content button {
  padding: 8px 18px;
  text-decoration: none;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 1000;
  font-size: 12px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
}

.Products .card-content button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 50px;
  background-color: #212121;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
}

.Products .card-content button:hover {
  color: #ffffff;
}
.Products .cards a {
  text-decoration: none;
}

.Products .card-content button:hover::before {
  width: 100%;
}
/* Why Choose Us? Section Start */
.Why-Choose-Us {
  background-image: url("./Images/Home/Home25.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.Why-Choose-Us .para {
  margin: 0 auto;
  width: 70%;
}
.Why-Choose-Us .client-section .card {
  padding: 4% 0 0 0;
  border: none;
  background: none;
  color: #fff;
}
.Why-Choose-Us .client-section .card p {
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}
.Why-Choose-Us .client-section .card img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
/* Client Count Up section Start */
.counter-client {
  padding: 5% 0;
}
.counter-client .plus,
.counter-client .CountUp {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 35px;
  color: #121085;
}
.counter-client p {
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  padding: 28px 0 0 0;
  margin: 0;
  color: #535353;
}

/* Our Services Section Start */
.Our-Services {
  background: #121085;
  height: 66vh;
}
.services-section .Mission .col-lg-6 {
  text-align: justify;
  width: 45% !important;
  margin: 0 auto;
}
.services-section .Vission .col-lg-6 {
  text-align: justify;
  width: 45% !important;
  margin: 0 auto;
}
.Our-Services .div-img {
  background: #fff;
  padding: 25px 38%;
  margin-bottom: 28px;
}
.Our-Services div img {
  width: 100px;
  height: 100px;
}
.Our-Services div h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  padding-top: 3%;
}
.Our-Services div p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  width: 80%;
  margin: 0 auto;
}
.Our-Services .row .col-lg-4 {
  padding: 0;
}
.Our-Services .row .br {
  border-right: 1px solid #ccc;
}

/* Brand-Associated Section Start */
.Brand-Associated img {
  width: 232px;
  height: 62px;
}
.Brand-Associated .row .col-lg-3 {
  margin: 3% 0;
}

/* Home Pages End */

/* Footer Page Start */
.Footer {
  background-color: #6f6f6f;
  padding: 4% 0 1% 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, #484649, #1e2c47);
}
.Footer img {
  width: 70%;
  margin: 0 auto;
  display: flex;
}
.Footer .pt img {
  width: 50%;
  margin: 0 auto;
  display: flex;
}
.Footer p a {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.Footer h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.footer-ph-with-mail {
  margin-top: 5%;
}
.footer-ph-with-mail p {
  margin: 0;
}
.footer-ph-with-mail p a {
  color: #fff;
  text-decoration: none;
}

/* Scan Me Text Start */
/* Scan Me Text End */
.copy-rights .pg {
  display: flex;
  justify-content: flex-start;
}
.copy-rights {
  padding: 2% 0 0 0;
  border-top: 1px solid #ccc;
}
.copy-rights .epl {
  display: flex;
  justify-content: flex-end;
}

/* Social Icon Section Start */

.Footer .footer-head .social-media {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.Footer .footer-head .social-media a {
  display: flex;
  background: #e3edf7;
  margin: 35px 6px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0);
  transition: transform 0.5s;
}

.Footer .footer-head .social-media a i {
  font-size: 35px;
  color: #777;
  padding: 8px;
  transition: transform 0.5s;
}

.Footer .footer-head .social-media a:hover {
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.01);
  transform: translateY(2px);
}

.Footer .footer-head .social-media a:hover i {
  transform: scale(0.9);
}
.Footer .footer-head .social-media a:hover .fa-facebook {
  color: #3b5998;
}
.Footer .footer-head .social-media a:hover .fa-twitter {
  color: #00acee;
}

.Footer .footer-head .social-media a:hover .fa-whatsapp {
  color: #25d366;
}
.Footer .footer-head .social-media a:hover .fa-linkedin-in {
  color: #3b5998;
}

/* Footer Page End */

/* Page  Loading Animation Start  */
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgb(255, 255, 255);
  z-index: 1;
}

.spinner {
  width: 6%;
  height: 14%;
  position: relative;
  top: 0%;
  left: 0%;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.loading-spinner {
  position: fixed;
  left: 76%;
  transform: translate(-50%, -50%) translateZ(0);
  top: 50%;
  margin: 0 auto;
  width: 1000px;
  height: 50px;
  z-index: 1;
  background-image: url("./Images/Comman/favicon.png");
  background-repeat: no-repeat;
  background-size: contain;
  /*animation: spinning 1.5s infinite ease-in-out;*/
}

.loading-spinner:after {
  content: "";
  position: absolute;
  top: -25px;
  left: -8px;
  width: 140px;
  height: 140px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100%;
  box-shadow: 0 2px 0 0 #121085;
  transition: all 1s linear;
  animation: lds-eclipse 1s linear infinite;
}

@keyframes spinning {
  0% {
    transform: translate(-50%, -50%) scale(1) translateZ(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1) translateZ(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1) translateZ(0);
  }
}

@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Page  Loading Animation End */
/* AboutUs-Pages Start*/
.AboutUs-Pages {
  background-image: url("./Images/About/About1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutus-pages1 {
  background-image: url("./Images/About/About1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-section p {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  text-align: justify;
  color: #3f3f3f;
  margin: 0 auto;
}
.Vission h3,
.Mission h3 {
  text-align: justify;
  line-height: 32px;
  font-size: 22px;
  padding: 10px 50px;
}

.Vission p,
.Mission p {
  text-align: justify;
  line-height: 32px;
  padding: 10px 50px;
}
.mission-1 {
  text-align: justify;
  width: 50%;
  line-height: 32px;
  padding: 0 50px;
}
.AboutUs .counter-client {
  background: #121085;
  padding: 5% 0;
}
.AboutUs .counter-client .plus,
.AboutUs .counter-client .CountUp {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 35px;
  color: #ffffff;
}
.AboutUs .counter-client p {
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
  padding: 5% 0;
}
.image-wrap .card .content img {
  width: 25px;
}
.image-wrap .card .content .star {
  display: flex;
}
.client-name {
  display: flex;
  line-height: 10px;
}
.client-name div {
  padding: 4px 0 0 18px;
}
.client-name img {
  width: 50px !important;
  height: 50px;
}
.double-quoute {
  position: relative;
  left: 90%;
  width: 44px !important;
  height: 44px !important;
}
.image-wrap .content {
  margin: 40px 60px;
  line-height: 40px;
}
/* Our Satisfied Clients Section Start */
.Our-Satisfied-Clients h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  color: #121085;
}

/* AboutUs-Pages End */

/* gallery-pages Start */
.Product-Pages,
.Gallery-home-banner {
  background-image: url("./Images/Gallery/Gallery1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery-section .row .col-lg-4 {
  margin: 30px 0;
}
.gallery-section img:hover {
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  transform: scale(1.2);
}
.gallery-section img {
  border-radius: 10px;
  transform: scale(1);
  width: 90%;
  /* margin-bottom: 60px; */
}

.gallery-section .content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.gallery-section .content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.gallery-section .content:hover .content-overlay {
  opacity: 1;
}

.gallery-section .content-image {
  width: 100%;
}

.gallery-section .content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.gallery-section .content:hover .content-details {
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 45% 0;
  opacity: 1;
  background: #0000008c;
}

.gallery-section .content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.gallery-section .content-details p {
  color: #fff;
  font-size: 0.8em;
}

.gallery-section .gallery-section .fadeIn-bottom {
  top: 80%;
}

.gallery-section .fadeIn-top {
  top: 20%;
}

.gallery-section .fadeIn-left {
  left: 20%;
}

.gallery-section .fadeIn-right {
  left: 80%;
}

/* gallery-pages End */

/* Services-Page Start */
.Services-home-banner {
  background-image: url("./Images/Services/Services1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Services-Page End */

/* AMC & REPAIRS Page Start */
.Amc-home-banner {
  background-image: url("./Images/Product/Amc/Amc1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Amc-section .Vission,
.Amc-section .Mission {
  /* width: 212px; */
  /* height: 45px; */
  /* background: #121085; */
  border-radius: 3px;
  /* color: #fff; */
}
.Amc-section .Vission button,
.Amc-section .Mission button {
  width: 212px;
  height: 45px;
  background: #121085;
  border-radius: 3px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
}
/* AMC & REPAIRS Page End */

/* Online-Ups Page Start */
.Online-Ups-home-banner {
  background-image: url("./Images/Product/OnlineUPS/OnlineUps1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Online-Ups-page .carousel {
  position: relative;
}

.feature-img .top-four-img .carousel-item img {
  width: 100%;
  position: relative;
  border: 2px solid #ccc;
  padding: 10px;
}
.feature-img .carousel-item img {
  object-fit: fill;
}

.feature-img #carousel-thumbs {
  background: rgba(255, 255, 255, 0.25);
  bottom: 0;
  left: 0;
  padding: 0;
  right: 0;
  width: 450px;
  padding: 0;
}
/* .Online-Ups-page #carousel-thumbs img {
  border: 5px solid transparent;
  cursor: pointer;
} */
/* .Online-Ups-page #carousel-thumbs img:hover {
  border-color: rgba(255, 255, 255, 0.3);
} */
.Online-Ups-page #carousel-thumbs .selected img {
  border-color: #fff;
}
.Online-Ups-page .carousel-control-prev,
.Online-Ups-page .carousel-control-next {
  width: 50px;
}
@media all and (max-width: 767px) {
  .Online-Ups-page .carousel-container #carousel-thumbs img {
    border-width: 3px;
  }
}
@media all and (min-width: 576px) {
  .Online-Ups-page .carousel-container #carousel-thumbs {
    position: absolute;
  }
}
@media all and (max-width: 576px) {
  .Online-Ups-page .carousel-container #carousel-thumbs {
    background: #ccccce;
  }
}
/* Donwload Section Start */

.Donwloadd {
  background: #ededed;
  padding: 5% 0;
}
.Donwloadd h4 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  color: #222222;
}
.Donwloadd .Request-Brochure {
  display: flex;
  justify-content: center;
}
.Brochure,
.Request {
  width: 352px;
  height: 45px;
  color: #fff;
  background: #121085;
  border-radius: 3px;
  margin: 0 26px;
}
/* Applications Section Start */
.Applications .row .col-lg-4 {
  margin-bottom: 3%;
}
.Applications img {
  width: 85%;
  border-radius: 10%;
  transform: scale(1);
}
.Applications img:hover {
  transform: scale(1.02);
  border-radius: 0%;
  transition: all 0.2s ease-in-out;
}

/* Mouse Hover To Image Zoom Start */

/* Mouse Hover To Image Zoom End */

/* Online-Ups Page End */

.Batteries-home-banner {
  background-image: url("./Images/Product/Battery/Battery1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Contact Us Page Start */
.ContactUs-home-banner {
  background-image: url("./Images/ContactUs/ContactUs1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Location Section Start */

.location .container-fluid {
  padding: 0;
}
.location .container-fluid iframe {
  width: 100%;
  height: 60vh;
}
/* Quickly Contact Section Start */
.Quickly-Contact {
  position: relative;
  bottom: 100px;
  width: 1316px;
  /* height: 735px; */
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0 auto;
}
.Quickly-Contact .validate-form form::placeholder {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #737373;
}
.address {
  width: 40%;
  margin: 0 auto;
}
.address p {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #737373;
}
.address-content {
  width: 530px;
  height: 365px;
  background: #121085;
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
}
.ph-with-mail-address {
  text-align-last: left;
}
.address-section {
  padding: 35px 53px;
  width: 85%;
}
.address-section .address-location {
  display: flex;
}
.address-section p a,
.address-section p {
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.address-section .fa-location-dot {
  position: relative;
  top: 32px;
  font-size: 26px;
  padding: 0 15px 0 0;
  color: #cfcee6;
}
.address-section p i {
  font-size: 26px;
  padding: 0 15px 0 0;
  color: #cfcee6;
  position: relative;
  top: 7px;
}
.Our-Associations img {
  width: 50%;
  margin: 0 auto;
  display: flex;
  opacity: 0.7;
}
.Our-Associations {
  position: relative;
  bottom: 100px;
  width: 1316px;
  /* height: 735px; */
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 12px auto;
}
/* Contact Us Page End */

/* ServoStabilizer Pages Start */
.ServoStabilizer-home-banner {
  background-image: url("./Images/Product/ServoStabilizer/ServoStabilizer1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-image img {
  cursor: pointer;
  width: 100%;
  height: 10vh;
  margin: 0px 0;
  padding: 4px;
}
.small-image img:focus-visible,
.small-image img:focus,
.small-image img:active,
.small-image img:hover {
  border: 2px solid #ccc;
  padding: 6%;
  transition: all 0.1s linear;
}

/* ServoStabilizer Pages End */

.Solar-products-home-banner {
  background-image: url("./Images/Product/SolarPanner/Solar1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .LightingSolutions-home-banner,
.Batteries-home-banner,
.ElectricalTransformer-home-banner, */

.ElectricalTransformer-home-banner {
  background-image: url("./Images/Product/Electricaltransformer/Electricaltransformer1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LightingSolutions-home-banner {
  background-image: url("./Images/Product/Lightning/Lightning2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UPS-home-banner {
  background-image: url("./Images/Product/Ups/Ups1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Invertors-home-banner {
  background-image: url("./Images/Product/Inventer/Inventer1.png");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Product page Start */
/* Product deatils Section Start */

/* Product page End */
