@media screen and (max-width: 1280px) {
  .loading-spinner {
    left: 88%;
  }
  /* Home Page Start */
  .Batteries,
  .Solar-products,
  .Online-Ups-page,
  .Invertors-page,
  .UPS-page,
  .ElectricalTransformer,
  .ServoStabilizer {
    overflow: hidden !important;
  }
  .modal {
    opacity: 100%;
    background: #0000008f;
  }
  .home-banner .carousel-caption {
    position: absolute;
    bottom: 8rem;
  }
  .home-bg-card-3 {
    height: 64vh;
  }
  .Why-Choose-Us .container {
    max-width: 100%;
  }
  .home-banner {
    padding-top: 126px;
    height: 80vh;
  }
  .home-bg-card-1 {
    height: 64vh;
  }
  .home-banner .owl-theme .owl-nav.disabled + .owl-dots {
    position: absolute;
    top: 30%;
  }
  .Products .card-img {
    height: 24vh;
  }
  .Our-Services {
    background: #121085;
    height: 60vh;
  }

  .home-header {
    font-size: 56px;
    position: relative;
    bottom: 50px;
  }
  .LightingSolutions-home-banner,
  .Batteries-home-banner,
  .ElectricalTransformer-home-banner,
  .Invertors-home-banner,
  .UPS-home-banner {
    height: 58vh;
  }

  /* About Us page Start */

  .Vission p,
  .Mission p {
    line-height: 30px;
    padding: 10px 22px;
    font-size: 16px;
  }
  .Vission h3,
  .Mission h3 {
    line-height: 30px;
    padding: 10px 22px;
  }
  .image-wrap .content {
    margin: 30px 60px;
  }

  /* Services Page Start */

  .Services-home-banner {
    height: 56vh;
  }

  /* Gallery Page Start */
  .header-para {
    width: 100%;
    margin: 0 auto;
    text-align: justify;
  }

  /* Contact Us Page Start */
  .ContactUs-home-banner {
    height: 55vh;
  }
  .address {
    width: 50%;
  }
  .address-section {
    width: 85%;
    margin: 0 auto;
  }
  .address-section p i {
    position: relative;
    top: 6px;
  }
  .Quickly-Contact {
    padding: 26px 0;
    width: 95%;
  }
  .location {
    height: 135vh;
  }
  .Our-Associations {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 40px auto;
    width: 95%;
    padding: 24px 0;
  }
  .ContactUs .f-m-b {
    margin-bottom: 42%;
  }
}
@media screen and (max-width: 1024px) {
  .loading-spinner {
    left: 94%;
  }
  .Home-page,
  .Batteries,
  .Solar-products,
  .Online-Ups-page,
  .Invertors-page,
  .UPS-page,
  .ElectricalTransformer,
  .ServoStabilizer {
    overflow: hidden !important;
  }
  /* Home Page Start */
  .Navigation .navbar-collapse ul li {
    padding: 0px 12px;
  }
  .home-banner .item .carousel-caption h5 {
    font-size: 46px;
    line-height: 56px;
  }
  .home-banner .carousel-caption {
    bottom: 2rem;
  }
  .home-banner .item .carousel-caption button {
    width: 220px;
    height: 43px;
  }
  .home-banner {
    height: 86vh;
  }
  .Products .card-content p {
    font-size: 14px;
  }
  .Why-Choose-Us .client-section .card p {
    font-size: 12px;
    line-height: 20px;
  }
  .counter-client .plus,
  .counter-client .CountUp {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 35px;
    color: #121085;
  }
  .counter-client p {
    font-size: 20px;
  }
  .counter-client {
    padding: 3% 0;
  }
  .Our-Services {
    background: #121085;
    height: 80vh;
  }
  .Our-Services div p {
    font-size: 13px;
    text-align: justify;
  }
  .para {
    font-size: 18px;
  }
  .Brand-Associated img {
    width: 100%;
    height: auto;
  }

  /* About Us page Start */
  .home-header {
    font-size: 44px;
    bottom: 30px;
  }
  .AboutUs .counter-client {
    padding: 3% 0;
  }
  .padding-top {
    padding-top: 126px;
  }

  .Vission p,
  .Mission p {
    line-height: 24px;
    padding: 0px 22px;
    font-size: 16px;
  }
  .AboutUs .counter-client .plus,
  .AboutUs .counter-client .CountUp {
    font-size: 46px;
  }
  .AboutUs .counter-client p {
    margin: 0;
  }
  .image-wrap .content {
    margin: 30px;
  }
  .image-wrap .content .copy {
    line-height: 25px;
    padding: 15px 0 0 0;
  }

  /* Product Page Start */
  .feature-img .top-four-img .carousel-item img {
    /* width: 414px !important; */
    /* height: 449px !important; */
    /* position: relative; */
    /* right: -15px; */
  }
  .ContactUs #contactForm .message-button button,
  .ContactUs #contactForm textarea,
  .ContactUs #contactForm input {
    width: 100% !important;
    height: 35px !important;
  }
  /* Services Page Start */

  /* Gallery Page Start */

  /* Contact Us Page Start */
}
@media screen and (max-width: 992px) {
  #carousel-selector-0,
  #carousel-selector-1,
  #carousel-selector-2,
  #carousel-selector-3 {
    max-width: 13%;
  }
  .Navigation .navbar-collapse ul li {
    padding: 15px 12px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    display: flex;
    justify-content: center;
    transition: all 0.2s linear;
  }
  .navbar-nav .dropdown-menu {
    width: 120%;
    line-height: 12px;
    /* left: -24px; */
    /* position: relative; */
  }
}
@media screen and (max-width: 914px) {
  .loading-spinner {
    left: 100%;
  }
  .Batteries,
  .Solar-products,
  .Online-Ups-page,
  .Invertors-page,
  .UPS-page,
  .ElectricalTransformer,
  .ServoStabilizer {
    overflow: hidden !important;
  }
  /* Home Page Start */
  .home-bg-card-1,
  .home-bg-card-2,
  .home-bg-card-3 {
    height: 34vh;
  }
  .home-banner .owl-theme .owl-nav.disabled + .owl-dots {
    position: absolute;
    top: 18%;
  }
  .home-banner .item .carousel-caption h5 {
    font-size: 40px;
    line-height: 50px;
  }
  .home-banner {
    height: 41vh;
  }
  .Products .card-content p {
    font-size: 20px;
  }
  .home-banner .owl-carousel {
    height: 32vh !important;
  }
  .owl-carousel .owl-stage-outer {
    height: 32vh;
  }
  .card-img-2 img {
    position: relative;
    bottom: -64px;
  }
  .Why-Choose-Us .client-section .card {
    width: 40%;
    margin: 0 auto;
  }
  .counter-client .counter {
    margin-top: 32px;
  }
  .Our-Services .container {
    max-width: 100%;
  }
  .Our-Services {
    background: #121085;
    height: auto;
  }
  .Brand-Associated .container {
    max-width: 100%;
  }
  .f-m-b {
    margin-bottom: 92%;
  }
  .footer-head .container {
    max-width: 95%;
  }
  .Footer .footer-head .social-media a {
    margin: 18px 6px;
  }
  .Footer h5 {
    padding: 40px 0 0 0;
  }
  .location {
    height: 162vh;
  }
  .ContactUs .f-m-b {
    margin-bottom: 50%;
  }
}
@media screen and (max-width: 768px) {
  .copy-rights p {
    font-size: 18px;
  }
  .copy-rights p a b {
    font-size: 18px;
    letter-spacing: 1px;
  }
  .copy-rights .epl,
  .copy-rights .pg {
    display: flex;
    justify-content: center;
  }
  .Navigation .modal.show .modal-dialog {
    height: 135%;
  }
  .loading-spinner {
    left: 105%;
  }
  .ServoStabilizer-home-banner,
  .home-banner {
    height: 52vh;
  }
  .home-banner .owl-theme .owl-nav.disabled + .owl-dots {
    top: 36%;
  }
  .Products .card-img {
    height: 38vh;
  }
  .owl-carousel .owl-stage-outer {
    height: 44vh;
  }
  .Our-Services {
    height: auto;
  }
  .Our-Services div p {
    font-size: 11px;
    margin: 16px auto;
    line-height: 20px;
    width: 90%;
  }
  .aboutus-pages1 {
    height: 47vh;
  }
  .Brand-Associated .row .col-lg-3 {
    width: 50%;
  }
  .Footer {
    position: relative;
  }

  .Navigation .navbar-collapse ul li {
    padding: 15px 12px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    display: flex;
    justify-content: center;
    transition: all 0.2s linear;
  }
  .dropdown-toggle {
    padding: 0;
  }
  .home-banner .item .carousel-caption h5 {
    font-size: 28px;
    line-height: 36px;
    margin: 12px 0;
  }
  .home-banner .carousel-caption {
    bottom: 0rem;
  }
  .home-banner .item .carousel-caption button {
    width: 178px;
  }
  .card-img-2 img {
    bottom: -25px;
  }
  .Why-Choose-Us .client-section .card p {
    font-size: 16px;
  }
  .f-m-b {
    margin-bottom: 0%;
  }
  .address {
    width: 100%;
  }
  .ContactUs .f-m-b {
    margin-bottom: 0;
  }
  .location {
    height: 240vh;
  }
  .Navigation .modal.show .modal-dialog {
    max-width: 88%;
  }
  .ContactUs #contactForm textarea {
    width: 100% !important;
    height: 28vh !important;
  }
  .about-section .col-md-6 {
    width: 100%;
  }
  .feature-img .top-four-img .carousel-item img {
    margin: 0 auto;
  }
  .Applications img {
    width: 85%;
    border-radius: 10%;
    margin: 8px 0;
    transform: scale(1);
  }
  .amc-section-2 .Mission {
    display: flex;
    flex-direction: column-reverse;
  }
  .Applications .row {
    display: flex;
    justify-content: center;
  }
  .ContactUs-home-banner {
    height: 46vh;
  }
  .ContactUs #contactForm .message-button button,
  .ContactUs #contactForm textarea,
  .ContactUs #contactForm input {
    height: 50px !important;
  }
  .location {
    height: 230vh;
  }
}
@media screen and (max-width: 679px) {
  .home-banner {
    height: 53vh;
  }
  .loading-spinner {
    left: 113%;
  }
  .ContactUs .f-m-b {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 540px) {
  .Batteries,
  .Solar-products,
  .Online-Ups-page,
  .Invertors-page,
  .UPS-page,
  .ElectricalTransformer,
  .ServoStabilizer {
    overflow: hidden !important;
  }
  .home-banner .item .carousel-caption p {
    font-size: 14px;
    line-height: 0px;
  }
  .home-banner .item .carousel-caption h5 {
    font-size: 28px;
    line-height: 30px;
  }
  .border-line {
    width: 40px;
    height: 2px;
  }
  .home-banner .item .carousel-caption button {
    width: 150px;
    height: 43px;
  }
  .home-banner .item .carousel-caption h5 {
    margin: 14px 0;
  }
  .home-banner .owl-theme .owl-dots .owl-dot span {
    width: 20px;
    height: 3px;
  }
  .home-banner .owl-theme .owl-nav.disabled + .owl-dots {
    position: absolute;
    top: 37%;
  }
  .home-bg-card-1,
  .home-bg-card-2,
  .home-bg-card-3 {
    height: 34vh;
  }
  .home-banner {
    height: 50vh;
  }
  .Products .card-content {
    padding: 18px 0;
  }
  .Products h3 {
    font-size: 36px;
  }
  .Products .card-img {
    height: 40vh;
  }
  .Products .card-content p {
    font-size: 28px;
  }
  .Products .card-content button {
    border-radius: 50px;
    font-size: 16px;
  }
  .card-img-2 img {
    bottom: 0px;
  }
  .Why-Choose-Us .para {
    width: 100%;
  }
  .Why-Choose-Us .client-section .card {
    width: 50%;
  }
  .loading-spinner {
    left: 130%;
  }
  .Our-Services {
    height: auto;
  }
  .Our-Services div p {
    font-size: 18px;
    line-height: 32px;
    width: 95%;
    padding: 5% 0;
  }
  .blue-header {
    font-size: 28px;
  }
  .gallery-section .row .col-lg-4 {
    margin: 15px 0;
  }
  .location .container-fluid iframe {
    width: 100%;
    height: 45vh;
  }
  .location {
    height: 143vh;
  }
  .ContactUs .f-m-b {
    margin-bottom: 50%;
  }
  .para {
    font-size: 22px;
    line-height: 30px;
    margin: 0 auto;
    width: 95%;
  }

  .Footer {
    position: relative;
  }
  .f-m-b {
    margin-bottom: 0;
  }

  /* About Us Page Start */
  .aboutus-pages1 {
    height: 28vh;
  }
  .home-header {
    font-size: 22px;
    bottom: 0;
    line-height: 33px;
  }
  .about-section .Vission {
    display: flex;
    flex-direction: column-reverse;
    padding: 24px 0;
  }
  .Our-Satisfied-Clients .owl-carousel .owl-stage-outer {
    height: 41vh;
  }
  .Services-home-banner {
    height: 28vh;
  }
  .services-section .Mission {
    text-align: justify;
    width: 45% !important;
    margin: 0 auto;
  }
  .services-section .Vission {
    text-align: justify;
    width: 45% !important;
    margin: 0 auto;
  }
  .Services-section .container .Vission {
    display: flex;
    flex-direction: column-reverse;
  }
  .Amc-home-banner {
    height: 28vh;
  }
  .Vission h3,
  .Mission h3 {
    font-size: 20px;
  }
  .amc-section-2 .Mission {
    display: flex;
    flex-direction: column-reverse;
  }
  .ContactUs-home-banner {
    height: 26vh;
  }

  .ContactUs #contactForm .message-button button,
  .ContactUs #contactForm textarea,
  .ContactUs #contactForm input {
    width: 100% !important;
    height: 40px !important;
    margin: 12px 0 !important;
  }
  .ContactUs #contactForm textarea {
    height: 150px !important;
  }

  .address {
    width: 100%;
  }
  .address-content {
    width: 100%;
    height: 300px;
  }
  .Our-Associations {
    position: relative;
    bottom: -400px;
  }
  .ContactUs .navbar-footer .Footer {
    padding: 4% 0 1% 0;
    position: relative;
    bottom: -195px;
    width: 100%;
    background-image: linear-gradient(to bottom, #484649, #1e2c47);
  }
  .Navigation .navbar .navbar-collapse ul li .dropdown-toggle {
    padding: 0;
  }
  .navbar-nav .dropdown-menu {
    width: 100%;
    line-height: 40px;
  }
  .Navigation .navbar-collapse ul li {
    padding: 12px 12px;
  }
  .Navigation .navbar-collapse ul li ul li {
    padding: 0px 12px;
  }
  .Navigation .navbar-collapse {
    transition: all 0.3s linear;
  }
  .ServoStabilizer-home-banner {
    height: 28vh;
  }
  /* .small-image img {
    width: 50%;
    position: relative;
    right: 36px;
  } */
  /* .feature-img .top-four-img .carousel-item img {
    width: 420px !important;
    height: 460px !important;
    position: relative;
    right: -5px;
  } */
  #carousel-selector-0,
  #carousel-selector-1,
  #carousel-selector-2,
  #carousel-selector-3 {
    max-width: 18%;
  }
  .LightingSolutions-home-banner,
  .Batteries-home-banner,
  .ElectricalTransformer-home-banner,
  .Invertors-home-banner,
  .UPS-home-banner {
    height: 28vh;
  }

  .Applications .container .row {
    display: flex;
    justify-content: center;
  }
  .Applications .container .row .col-lg-2 {
    margin: 16px 0;
  }
  .small-image img {
    height: 10vh;
    margin: 13px 0;
  }
  .Online-Ups-home-banner {
    height: 28vh;
  }
  .Solar-products-home-banner {
    height: 28vh;
  }
  .Product-Pages,
  .Gallery-home-banner {
    height: 28vh;
  }
  .Navigation .modal.show .modal-dialog {
    transform: none;
    max-width: 100%;
    display: flex;
    align-items: center;
    height: 108%;
  }
  .modal-dialog .modal-content .modal-body p {
    width: 100% !important;
    font-size: 14px !important;
  }
  .modal-dialog .modal-content .modal-body #contactForm input {
    margin: 4px 0 !important;
  }
}
@media screen and (max-width: 426px) {
  .ph-with-mail {
    font-size: 12px;
  }
  .home-banner {
    height: 53vh;
  }
  .para {
    font-size: 14px;
  }
  /* .Our-Services {
    height: 197vh;
  } */

  .loading-spinner {
    left: 150%;
  }
  .AboutUs .counter-client p {
    font-size: 18px;
  }
  .Our-Satisfied-Clients .owl-carousel .owl-stage-outer {
    height: 54vh;
  }
  .address-section {
    width: 100%;
    margin: 0 auto;
    padding: 35px 17px;
  }
  .Navigation .modal.show .modal-dialog {
    height: 125%;
  }
  .Brochure,
  .Request {
    height: 56px;
  }
}
@media screen and (max-width: 415px) {
  .loading-spinner {
    left: 155%;
  }
  .Navigation .modal.show .modal-dialog {
    height: 86%;
  }
  .location .container-fluid iframe {
    height: 35vh;
  }
  .ph-with-mail p {
    margin: 0 0;
    font-size: 12px;
  }
  .Navigation img {
    width: 75%;
  }
  .home-banner {
    padding-top: 103px;
    height: 34vh;
  }
  .home-banner .carousel-caption {
    bottom: 0rem;
  }
  .home-banner .item .carousel-caption h5 {
    font-size: 22px;
    line-height: 28px;
  }
  .home-banner .item .carousel-caption p {
    font-size: 14px;
    line-height: 0px;
    margin: 0;
  }
  .home-banner .owl-theme .owl-nav.disabled + .owl-dots {
    position: absolute;
    top: 28%;
  }
  .home-bg-card-1,
  .home-bg-card-2,
  .home-bg-card-3 {
    height: 22vh;
  }
  .home-banner .item .carousel-caption button {
    width: 100px;
    height: 44px;
    font-size: 12px;
  }
  .Products .card-img {
    height: 28vh;
  }
  .para {
    font-size: 14px;
  }
  /* .Our-Services {
    height: 138vh;
  } */
  .Services-home-banner,
  .Amc-home-banner,
  .Product-Pages,
  .Gallery-home-banner,
  .ContactUs-home-banner,
  .ServoStabilizer-home-banner,
  .aboutus-pages1 {
    height: 18vh;
  }
  .about-section p {
    font-size: 13px;
    line-height: 28px;
  }
  .AboutUs .counter-client p {
    font-size: 18px;
  }
  .AboutUs .counter-client .plus,
  .AboutUs .counter-client .CountUp {
    font-size: 32px;
  }
  .loading-spinner {
    left: 154%;
    top: 33%;
  }
  .Products h3 {
    font-size: 28px;
  }
  .Products .card-content p {
    font-size: 24px;
  }
  .blue-header {
    font-size: 22px;
  }
  .ContactUs #contactForm .message-button button,
  .ContactUs #contactForm textarea,
  .ContactUs #contactForm input {
    width: 330px !important;
    height: 35px !important;
  }
  .ContactUs #contactForm textarea {
    height: 150px !important;
  }
  .address-content {
    width: 353px;
    height: 300px;
  }
  .Our-Associations {
    position: relative;
    bottom: -300px;
  }
  .ContactUs #contactForm .message-button button,
  .ContactUs #contactForm textarea,
  .ContactUs #contactForm input {
    width: 100% !important;
  }
  .feature-img #carousel-thumbs {
    width: 400px;
    padding: 0 0px;
  }
  .Donwloadd h4 {
    font-size: 16px;
    line-height: 25px;
  }
  .location {
    height: 145vh;
  }
  .Brochure,
  .Request {
    font-size: 12px;
  }
  .ContactUs .f-m-b {
    margin-bottom: 28%;
  }
  x .small-image img {
    height: 10vh;
    margin: 5px 0;
  }

  .padding-top {
    padding-top: 103px;
  }
}
@media screen and (max-width: 395px) {
  .loading-spinner {
    left: 160%;
    top: 50%;
  }
  .Why-Choose-Us .para {
    width: 100%;
    text-align: justify;
  }
  .Why-Choose-Us .client-section .card {
    width: 90%;
  }
  /* .Our-Services {
    height: 145vh;
  } */
  .home-header {
    font-size: 24px;
    bottom: 0;
    line-height: 24px;
  }
  .about-section p {
    font-size: 12px;
    line-height: 24px;
  }
  .Our-Satisfied-Clients .owl-carousel .owl-stage-outer {
    height: 38vh;
  }
  .location .container-fluid iframe {
    width: 100%;
    height: 25vh;
  }
  .address p {
    font-size: 14px;
    line-height: 24px;
  }
  .address-section {
    width: 83%;
    padding: 35px 8px;
  }
  .address-content {
    width: 350px;
    height: 300px;
  }
  .location {
    height: 114vh;
  }
  .Navigation .modal.show .modal-dialog {
    height: 90%;
  }
  .modal-header {
    padding: 8px 24px;
  }
  .home-banner {
    padding-top: 103px;
    height: 39vh;
  }
  .ContactUs #contactForm .message-button button,
  .ContactUs #contactForm textarea,
  .ContactUs #contactForm input {
    width: 100% !important;
    height: 35px !important;
  }
  .feature-img .top-four-img .carousel-item img {
    width: 420px !important;
    height: 440px !important;
  }
  .Batteries,
  .Solar-products,
  .Online-Ups-page,
  .Invertors-page,
  .UPS-page,
  .ElectricalTransformer,
  .ServoStabilizer {
    overflow: hidden;
  }
  .Solar-products-home-banner,
  .Online-Ups-home-banner,
  .LightingSolutions-home-banner,
  .Batteries-home-banner,
  .ElectricalTransformer-home-banner,
  .Invertors-home-banner,
  .UPS-home-banner {
    height: 18vh;
  }
  .Invertors .feature-img .top-four-img .carousel-item img,
  .feature-img .top-four-img .carousel-item img {
    width: 287px !important;
    height: 440px !important;
  }
  .UPS .feature-img .top-four-img .carousel-item img {
    width: 320px !important;
    height: 440px !important;
  }
  .address-section p a,
  .address-section p {
    font-size: 14px;
  }
  .home-banner .item .carousel-caption h5 {
    font-size: 18px;
    line-height: 28px;
  }
  .home-banner .item .carousel-caption {
    display: none;
  }
  .ContactUs #contactForm textarea {
    height: 45px !important;
  }
  .counter-client p {
    font-size: 17px;
    padding: 9px 0 0 0;
  }
}
@media screen and (max-width: 376px) {
  .copy-rights p {
    font-size: 14px;
  }
  .copy-rights p a b {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .home-banner {
    padding-top: 103px;
    height: 38vh;
  }
  .Quickly-Contact {
    padding: 26px 0;
    width: 95%;
    bottom: 16px;
  }
  .location {
    height: 121vh;
  }
  .text-start {
    font-size: 14px;
  }
  .ContactUs .f-m-b {
    margin-bottom: 57%;
  }
  .Products .cards {
    background: #f0f0f0;
    margin: 20px 30px;
  }
  /* .Our-Services {
    height: 195vh;
  } */
  .Brand-Associated img {
    width: 100%;
    height: auto;
  }
  .home-header {
    font-size: 20px;
    bottom: 0;
    line-height: 24px;
  }
  .Vission h3,
  .Mission h3 {
    font-size: 17px;
  }
  .location {
    height: 112vh;
  }
  .Navigation .modal.show .modal-dialog {
    height: 100%;
  }
  .Navigation img {
    width: 67%;
    padding: 6px 0;
  }
  #contactForm {
    width: 88%;
  }
  .Our-Associations {
    position: relative;
    bottom: -393px;
  }
  .address-content {
    width: 332px;
    height: 300px;
  }
  .loading-spinner {
    left: 165%;
    top: 50%;
  }
  .Our-Satisfied-Clients .owl-carousel .owl-stage-outer {
    height: 52vh;
  }
}
@media screen and (max-width: 361px) {
  .loading-spinner {
    left: 170%;
  }
  .counter-client p {
    font-size: 16px;
    padding: 12px 0 0 0;
  }
  .home-header {
    font-size: 16px;
  }
  .Vission p,
  .Mission p {
    padding: 0px;
  }
  .Our-Services div p {
    font-size: 15px;
  }
  /* .Our-Services {
    height: 156vh;
  } */
  .Brand-Associated img {
    width: 75px;
    height: 28px;
  }
  .Our-Satisfied-Clients .owl-carousel .owl-stage-outer {
    height: 46vh;
  }
  .Vission h3,
  .Mission h3 {
    font-size: 14px;
  }
  .location {
    height: 101vh;
  }
  .address-content {
    width: 315px;
    height: 300px;
  }
  .Our-Associations {
    position: relative;
    bottom: -386px;
  }
  .loading-spinner {
    left: 170%;
    top: 40%;
  }
  .home-banner {
    padding-top: 103px;
    height: 42vh;
  }
  .small-image img {
    width: 500%;
    position: relative;
    right: 0px;
  }
  .feature-img #carousel-thumbs {
    width: 100%;
    padding: 0 0px;
  }
  .UPS .feature-img .top-four-img .carousel-item img,
  .Invertors .feature-img .top-four-img .carousel-item img,
  .feature-img .top-four-img .carousel-item img {
    width: 287px !important;
    height: 329px !important;
  }
  .small-image img {
    height: 10vh;
    margin: 0px 0;
  }
}
@media screen and (max-width: 320px) {
  #carousel-selector-0,
  #carousel-selector-1,
  #carousel-selector-2,
  #carousel-selector-3 {
    max-width: 28%;
  }
  .Navigation .navbar-collapse ul li a {
    font-weight: 600;
    font-size: 14px;
    color: #000 !important;
    text-decoration: none;
  }
  .location {
    height: 136vh;
  }
  .small-image img {
    height: 10vh;
    margin: 0px 0;
  }
  .counter-client .plus,
  .counter-client .CountUp {
    font-size: 36px;
  }
  .small-image img {
    width: 500%;
    position: relative;
    right: 0px;
  }
  .feature-img #carousel-thumbs {
    width: 100%;
    padding: 0 0px;
  }
  .UPS .feature-img .top-four-img .carousel-item img,
  .Invertors .feature-img .top-four-img .carousel-item img,
  .feature-img .top-four-img .carousel-item img {
    width: 287px !important;
    height: 329px !important;
  }
  .ContactUs .f-m-b {
    margin-bottom: 27%;
  }
  .counter-client p {
    padding: 0px 0 0 0;
  }
  .home-header {
    font-size: 18px;
    bottom: 0;
    line-height: 24px;
  }
  /* .Our-Services {
    height: 196vh;
  } */
  .Brand-Associated img {
    width: 100%;
    height: auto;
  }
  .Navigation .modal.show .modal-dialog {
    height: 96%;
  }
  .ph-with-mail p {
    margin: 0 0;
    font-size: 10px;
    padding: 5px 0;
  }
  .about-section p {
    padding: 0px 0px;
  }
  .Vission p,
  .Mission p {
    padding: 0px;
  }
  .home-banner {
    padding-top: 103px;
    height: 40vh;
  }
  .Footer p a {
    font-size: 12px;
    line-height: 19px;
  }
  .Footer h5 {
    font-size: 16px;
  }
  .AboutUs .counter-client p {
    font-size: 16px;
  }
  .Our-Satisfied-Clients .owl-carousel .owl-stage-outer {
    height: 57vh;
  }
  .copy-rights {
    font-size: 10px;
  }
  .Vission h3,
  .Mission h3 {
    font-size: 15px;
  }
  .address-content {
    width: 280px;
    height: 300px;
  }
  .Our-Associations {
    position: relative;
    bottom: -270px;
  }
  .address-section p a,
  .address-section p {
    font-size: 12px;
  }
  .loading-spinner {
    left: 185%;
    top: 50%;
  }
}
